import React from 'react';
import './App.css';
import Landing from '../src/screens/Landing';
import 'typeface-roboto';


function App() {
  return (
    <>
      <Landing />
    </>
  );
}

export default App;
